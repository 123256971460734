import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { qmFetchTCA, QMTCAInput } from '@berkindale/berkindale-provider-quotemedia-javascript-api';
import { RootState } from "../../app/store";

export interface TCAFilters {
    date: string | undefined;
    tickers: string[];
}

export interface TCAState {
    filters: TCAFilters;
    loading: boolean;
    error: string | null;
    data: any[]
}

const initialState: TCAState = {
    filters: {
        date: "",
        tickers: []
    },
    loading: false,
    error: null,
    data:[]
};


export const fetchTCA = createAsyncThunk<
  any, 
  QMTCAInput
>("TCA/fetchTCA", async (input, thunkAPI) => {
  try {
    const resp = await qmFetchTCA(input);
    return resp;
  } catch (error: any) {
    console.error("fetchTCA ThunkAPI error", error);
    return thunkAPI.rejectWithValue(error || "Unknown error occurred");
  }
});


const tcaSlice = createSlice({
    name: "TCA",
    initialState,
    reducers: {
        updateFilters(state, action: PayloadAction<Partial<TCAFilters>>) {
            state.filters = { ...state.filters, ...action.payload };
        }
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchTCA.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchTCA.fulfilled, (state, action) => {
          state.loading = false;
          console.log('error', action.payload)
          state.data = action.payload;
        })
        .addCase(fetchTCA.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload as string;
        });
    },
  });

  export const selectTCAFilters = (state: RootState) => state.TCA.filters;
  export const selectTCAData = (state: RootState) => state.TCA.data;
  export const selectTCALoading = (state: RootState) => state.TCA.loading;
  
  export const { updateFilters } =
    tcaSlice.actions;
   
  export const TCAReducer =  tcaSlice.reducer;

