import { useNavigate, useLocation } from "react-router-dom";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer, { DrawerProps } from "@mui/material/Drawer";
import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import {
  AccessTime,
  Download,
  ShowChart,
  Summarize,
  FiberNew
} from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SsidChartIcon from '@mui/icons-material/SsidChart';
import { updateSelectedCountry } from "../domain/domainSlice";
import { useDispatch } from "react-redux";

const menuItems = [
  {
    id: 1,
    name: "Time Travel",
    icon: AccessTime,
    to: "/timetravel",
  },
  {
    id: 2,
    name: "Top of Book",
    icon: ShowChart,
    to: "/tob",
  },
  {
    id: 4,
    name: "Sentiment Analysis",
    icon: SentimentSatisfiedAltIcon,
    to: "/sentiment",
  },
  {
    id: 5,
    name: "AI Reports",
    icon: Summarize,
    to: "/AIReports",
  },
  {
    id: 6,
    name: "Reports",
    icon: Summarize,
    to: "/reports",
    hidden: true,
  },
  {
    id: 7,
    name: "Matched Trends",
    icon: SsidChartIcon,
    to: "/matched-trends",
  },
  {
    id: 8,
    name: "Downloads",
    icon: Download,
    to: "/downloads",
  },
  {
    id: 9,
    name: "TCA",
    icon: FiberNew,
    to: "/TCA",
  },
];

const openedMixin = (theme: Theme, drawerwidth: number): CSSObject => ({
  width: drawerwidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface BaDrawerProps extends DrawerProps {
  open?: boolean;
  drawerwidth: number;
}
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})<BaDrawerProps>(({ theme, open, drawerwidth }) => ({
  width: drawerwidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme, drawerwidth),
    "& .MuiDrawer-paper": openedMixin(theme, drawerwidth),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface MenuDrawerProps {
  open: boolean;
  handleDrawerClose: () => void;
  drawerwidth: number;
}

export function MenuDrawer({
  open,
  handleDrawerClose,
  drawerwidth,
}: MenuDrawerProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const menuItemsArray = menuItems
    .filter((menuItem) => {
      if (import.meta.env.VITE_HIDE_UNFINISHED) {
        return !menuItem.hidden;
      } else {
        return menuItem;
      }
    })
    .map((menuItem, idx) => (
      <ListItem
        component={Button}
        selected={menuItem.to === location.pathname}
        onClick={() => { 
          if(menuItem.id !== 1){
            dispatch(updateSelectedCountry('Canada'));
          }
          navigate(menuItem.to)
        }}
        key={idx}
        disablePadding
        sx={{ display: "block" }}
      >
        <Tooltip title={menuItem.name} placement="right">
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <menuItem.icon />
            </ListItemIcon>
            <ListItemText
              primary={menuItem.name}
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </Tooltip>
      </ListItem>
    ));
  return (
    <Drawer variant="permanent" open={open} drawerwidth={drawerwidth}>
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>{menuItemsArray}</List>
    </Drawer>
  );
}
