// App.tsx

// This pattern (<MyRoutes> etc.) was patterned after
// https://github.com/remix-run/react-router/blob/dev/examples/auth/src/App.tsx and
// https://ui.docs.amplify.aws/react/guides/auth-protected

import { lazy, Suspense, createContext, useState, useMemo } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import "./styles.css";
import { Layout } from "../pages/layout/Layout";
import { RequireAuth } from "./RequireAuth";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline, Typography } from "@mui/material";
import { PaletteMode } from "@mui/material";
import { BkSpinner } from "../pages/common/BkSpinner";
import {
  analyticsLinks,
  apiAuthenticationGuideLinks,
} from "../pages/help/helpGuideLinks";


import { ThemeOptions } from "@mui/material/styles";
import { useAppSelector } from "./hooks";
import { selectUserInfo, selectUserInfoLoading } from "../pages/login/loginSlice";
import { Box } from "@mui/system";

declare module "@mui/material/styles" {
  interface Theme {
    matchedTrends: {
      background: {
        paper: string;
      };
    };
  }

  interface ThemeOptions {
    matchedTrends?: {
      background?: {
        paper?: string;
      };
    };
  }
}


// To learn about splitting up the code bundle see https://reactjs.org/docs/code-splitting.html
// Code splitting helps you 'lazy-load' just the things that are currently needed by the user.

// dynamic import example:
// import("./math").then(math => {
//   console.log(math.add(16, 26));
// });
// When Webpack sees this, it automatically starts code-splitting your app.
// If using create-react-app, no additional Webpack config is necessary.

// React.lazy lets you render a dynamic import as a regular React component.
// React.lazy takes a function that calls a dynamic import.
// The lazy component should be rendered inside a <Suspense> component, which shows some fallback content while the lazy component is loading.
const Login = lazy(() =>
  import("../pages/login/Login").then(({ Login }) => ({
    default: Login,
  }))
);
const LoginPlus = () => (
  <Suspense fallback={<BkSpinner />}>
    <Login />
  </Suspense>
);

const TimeTravelPage = lazy(() =>
  import("../pages/timeTravel/TimeTravelPage").then(({ TimeTravelPage }) => ({
    default: TimeTravelPage,
  }))
);
const TimeTravelPagePlus = () => (
  <Suspense fallback={<BkSpinner />}>
    <TimeTravelPage />
  </Suspense>
);

const TOBPage = lazy(() =>
  import("../pages/TOB/TOBPage").then(({ TOBPage }) => ({
    default: TOBPage,
  }))
);
const TOBPagePlus = () => (
  <Suspense fallback={<BkSpinner />}>
    <TOBPage />
  </Suspense>
);

const SentimentPage = lazy(() =>
  import("../pages/sentiment/SentimentPage").then(({ SentimentPage }) => ({
    default: SentimentPage,
  }))
);
const SentimentPagePlus = () => (
  <Suspense fallback={<BkSpinner />}>
    <SentimentPage />
  </Suspense>
);

const AIReportsPage = lazy(() =>
  import("../pages/aiReports/AIReportsPage").then(({ AIReportsPage }) => ({
    default: AIReportsPage,
  }))
);
const AIReportsPagePlus = () => (
  <Suspense fallback={<BkSpinner />}>
    <AIReportsPage />
  </Suspense>
);

const DownloadsPage = lazy(() =>
  import("../pages/downloads/DownloadsPage").then(({ DownloadsPage }) => ({
    default: DownloadsPage,
  }))
);
const DownloadsPagePlus = () => (
  <Suspense fallback={<BkSpinner />}>
    <DownloadsPage />
  </Suspense>
);


const TCAPage = lazy(() =>
  import("../pages/tca/TCA").then(({ default: TCAPage }) => ({
    default: TCAPage,
  }))
);
const TCAPagePlus = () => (
  <Suspense fallback={<BkSpinner />}>
    <TCAPage />
  </Suspense>
);

const HelpGuideTemplate = lazy(() =>
  import("../pages/help/HelpGuideTemplate").then(({ HelpGuideTemplate }) => ({
    default: HelpGuideTemplate,
  }))
);

const APIUserGuidePlus = () => (
  <Suspense fallback={<BkSpinner />}>
    <HelpGuideTemplate
      helpGuideTitle="API User Guide"
      markdownFilename="/help/api-authentication-guide.md" // just a placeholder - not an accurate filename
      links={apiAuthenticationGuideLinks}
    />
  </Suspense>
);

const APIAuthenticationGuidePlus = () => (
  <Suspense fallback={<BkSpinner />}>
    <HelpGuideTemplate
      helpGuideTitle="API Authentication Guide"
      markdownFilename="/help/api-authentication-guide.md"
      links={apiAuthenticationGuideLinks}
    />
  </Suspense>
);

const ContactPage = lazy(() =>
  import("../pages/contact/ContactPage").then(({ ContactPage }) => ({
    default: ContactPage,
  }))
);

const ContactPagePlus = () => (
  <Suspense fallback={<BkSpinner />}>
    <ContactPage />
  </Suspense>
);

const AnalyticsGuideAbout = () => (
  <Suspense fallback={<BkSpinner />}>
    <HelpGuideTemplate
      helpGuideTitle="Analytics Guide"
      markdownFilename="/analytics-docs/index.md"
      links={analyticsLinks}
    />
  </Suspense>
);

const AnalyticsGuideAIOverview = () => (
  <Suspense fallback={<BkSpinner />}>
    <HelpGuideTemplate
      helpGuideTitle="Analytics Guide"
      markdownFilename="/analytics-docs/advanced-insights/index.md"
      links={analyticsLinks}
    />
  </Suspense>
);

const AnalyticsGuideDailySummary = () => (
  <Suspense fallback={<BkSpinner />}>
    <HelpGuideTemplate
      helpGuideTitle="Analytics Guide"
      markdownFilename="/analytics-docs/advanced-insights/daily_summary.md"
      links={analyticsLinks}
    />
  </Suspense>
);

const AnalyticsGuideDepth = () => (
  <Suspense fallback={<BkSpinner />}>
    <HelpGuideTemplate
      helpGuideTitle="Analytics Guide"
      markdownFilename="/analytics-docs/advanced-insights/depth.md"
      links={analyticsLinks}
    />
  </Suspense>
);

const AnalyticsGuideOrders = () => (
  <Suspense fallback={<BkSpinner />}>
    <HelpGuideTemplate
      helpGuideTitle="Analytics Guide"
      markdownFilename="/analytics-docs/advanced-insights/orders.md"
      links={analyticsLinks}
    />
  </Suspense>
);

const AnalyticsGuideTOB = () => (
  <Suspense fallback={<BkSpinner />}>
    <HelpGuideTemplate
      helpGuideTitle="Analytics Guide"
      markdownFilename="/analytics-docs/advanced-insights/top_of_book.md"
      links={analyticsLinks}
    />
  </Suspense>
);

const AnalyticsGuideTrades = () => (
  <Suspense fallback={<BkSpinner />}>
    <HelpGuideTemplate
      helpGuideTitle="Analytics Guide"
      markdownFilename="/analytics-docs/advanced-insights/trades.md"
      links={analyticsLinks}
    />
  </Suspense>
);

const NoMatch = lazy(() =>
  import("../pages/noMatch/NoMatch").then(({ NoMatch }) => ({
    default: NoMatch,
  }))
);

const NoMatchPlus = () => (
  <Suspense fallback={<BkSpinner />}>
    <NoMatch />
  </Suspense>
);

// Settings items
const ProfilePage = lazy(() =>
  import("../pages/profile/ProfilePage").then(({ ProfilePage }) => ({
    default: ProfilePage,
  }))
);

const ProfilePagePlus = () => (
  <Suspense fallback={<BkSpinner />}>
    <ProfilePage />
  </Suspense>
);

const SecurityPage = lazy(() =>
  import("../pages/security/SecurityPage").then(({ SecurityPage }) => ({
    default: SecurityPage,
  }))
);

const SecurityPagePlus = () => (
  <Suspense fallback={<BkSpinner />}>
    <SecurityPage />
  </Suspense>
);

const MatchedTrendsPage = lazy(() =>
  import("../pages/matchedTrends/MatchedTrendsPage").then(({ MatchedTrendsPage }) => ({
    default: MatchedTrendsPage,
  }))
);
const MatchedTrendsPagePlus = () => (
  <Suspense fallback={<BkSpinner />}>
    <MatchedTrendsPage />
  </Suspense>
);



const NoAccess = lazy(() =>
  import("../pages/noAccess/NoAccess").then(({ NoAccess }) => ({
    default: NoAccess,
  }))
);

const NoAccessPlus = ({ moduleName }: { moduleName: string }) => (
  <Suspense fallback={<BkSpinner />}>
    <NoAccess moduleName={moduleName}/>
  </Suspense>
);

function MyRoutes() {

  const userInfo = useAppSelector(selectUserInfo);
  const userInfoLoading = useAppSelector(selectUserInfoLoading);

  const authorizedModules = userInfo?.authorized_modules || [];
  const moduleMapping: { [key: string]: string } = {
    "AI Reports": "AI_REPORTS",
    "Downloads": "DOWNLOADS",
    "Matched Trends": "MATCHED_TRENDS",
    "Sentiment Analysis": "SENTIMENT_ANALYSIS",
    "Time Travel": "TIME_TRAVEL",
    "Top of Book": "TOP_OF_BOOK",
    "TCA": "TCA",
  };
  const isAuthorized = (moduleKey: string) =>
    authorizedModules.includes(moduleKey);
  
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route
            index
            element={
              <RequireAuth>
                <Navigate to="/timetravel" />
              </RequireAuth>
            }
          />
          <Route
            path="/timetravel"
            element={
          <RequireAuth>
            {userInfoLoading ? (
              // Show loader while checking authorization
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="90vh">
                <BkSpinner />
                <Typography variant="h6" mt={2} color="textSecondary" sx={{font: "bold", textAlign: "center", color: "white", marginLeft : "20px" }}>
                Loading.. Please wait..
              </Typography>
              </Box>
            ) : isAuthorized(moduleMapping["Time Travel"]) ? (
              <TimeTravelPagePlus />
            ) : (
              <NoAccessPlus moduleName="Time Travel" />
            )}
          </RequireAuth>
            }
          />

          <Route
            path="/tob"
            element={
              <RequireAuth>
                 {userInfoLoading ? (
              // Show loader while checking authorization
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="90vh">
                <BkSpinner />
                <Typography variant="h6" mt={2} color="textSecondary" sx={{font: "bold", textAlign: "center", color: "white", marginLeft : "20px" }}>
                Loading.. Please wait..
              </Typography>
              </Box>
            ) : isAuthorized(moduleMapping["Top of Book"]) ? (
                <TOBPagePlus />
              ) : (
                  <NoAccessPlus moduleName="Top of Book" />
                )}
              </RequireAuth>
            }
          />
          <Route
            path="/sentiment"
            element={
              <RequireAuth>
                 {userInfoLoading ? (
              // Show loader while checking authorization
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="90vh">
                <BkSpinner />
                <Typography variant="h6" mt={2} color="textSecondary" sx={{font: "bold", textAlign: "center", color: "white", marginLeft : "20px" }}>
                Loading.. Please wait..
              </Typography>
              </Box>
            ) : isAuthorized(moduleMapping["Sentiment Analysis"]) ? (
                <SentimentPagePlus />
              ) : (
                  <NoAccessPlus moduleName="Sentiment Analysis" />
                )}
              </RequireAuth>
            }
          />
          {/* {!import.meta.env.VITE_HIDE_UNFINISHED && (
            <Route
              path="/reports"
              element={
                <RequireAuth>
                  <ReportsPagePlus />
                </RequireAuth>
              }
            />
          )} */}

        <Route
            path="/AIreports"
            element={
              <RequireAuth>
                 {userInfoLoading ? (
              // Show loader while checking authorization
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="90vh">
                <BkSpinner />
                <Typography variant="h6" mt={2} color="textSecondary" sx={{font: "bold", textAlign: "center", color: "white", marginLeft : "20px" }}>
                Loading.. Please wait..
              </Typography>
              </Box>
            ) : isAuthorized(moduleMapping["AI Reports"]) ? (
                <AIReportsPagePlus />
              ) : (
                  <NoAccessPlus moduleName="AI Reports" />
                )}
              
              </RequireAuth>
            }
          />


          <Route
            path="/downloads"
            element={
              <RequireAuth>
                 {userInfoLoading ? (
              // Show loader while checking authorization
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="90vh">
                <BkSpinner />
                <Typography variant="h6" mt={2} color="textSecondary" sx={{font: "bold", textAlign: "center", color: "white", marginLeft : "20px" }}>
                Loading.. Please wait..
              </Typography>
              </Box>
            ) : isAuthorized(moduleMapping["Downloads"]) ? (
                <DownloadsPagePlus />
              ) : (
                  <NoAccessPlus moduleName="Downloads"/>
                )}
              </RequireAuth>
            }
          />


          <Route
            path="/TCA"
            element={
              <RequireAuth>
                 {userInfoLoading ? (
              // Show loader while checking authorization
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="90vh">
                <BkSpinner />
                <Typography variant="h6" mt={2} color="textSecondary" sx={{font: "bold", textAlign: "center", color: "white", marginLeft : "20px" }}>
                Loading.. Please wait..
              </Typography>
              </Box>
            ) : isAuthorized(moduleMapping["TCA"]) ? (
                <TCAPagePlus />
              ) : (
                  <NoAccessPlus moduleName="TCA"/>
                )}
              </RequireAuth>
            }
          />
          

          <Route path="/login" element={<LoginPlus />} />
          <Route
            path="/api-user-guide"
            element={
              <RequireAuth>
                <APIUserGuidePlus />
              </RequireAuth>
            }
          />
          <Route
            path="/api-authentication-guide"
            element={
              <RequireAuth>
                <APIAuthenticationGuidePlus />
              </RequireAuth>
            }
          />
          <Route path="/analytics-guide" element={<AnalyticsGuideAbout />} />
          <Route
            path="/analytics-guide/advanced-insights"
            element={
              <RequireAuth>
                <AnalyticsGuideAIOverview />
              </RequireAuth>
            }
          />
          <Route
            path="/analytics-guide/advanced-insights/daily_summary"
            element={
              <RequireAuth>
                <AnalyticsGuideDailySummary />
              </RequireAuth>
            }
          />
          <Route
            path="/analytics-guide/advanced-insights/depth"
            element={
              <RequireAuth>
                <AnalyticsGuideDepth />
              </RequireAuth>
            }
          />
          <Route
            path="/analytics-guide/advanced-insights/orders"
            element={
              <RequireAuth>
                <AnalyticsGuideOrders />
              </RequireAuth>
            }
          />
          <Route
            path="/analytics-guide/advanced-insights/top_of_book"
            element={
              <RequireAuth>
                <AnalyticsGuideTOB />
              </RequireAuth>
            }
          />
          <Route
            path="/analytics-guide/advanced-insights/trades"
            element={
              <RequireAuth>
                <AnalyticsGuideTrades />
              </RequireAuth>
            }
          />
          <Route
            path="/contact-support"
            element={
              <RequireAuth>
                <ContactPagePlus />
              </RequireAuth>
            }
          />
          <Route
            path="/profile"
            element={
              <RequireAuth>
                <ProfilePagePlus />
              </RequireAuth>
            }
          />
          <Route
            path="/security"
            element={
              <RequireAuth>
                <SecurityPagePlus />
              </RequireAuth>
            }
          />
          <Route
            path="/matched-trends"
            element={
              <RequireAuth>
                 {userInfoLoading ? (
              // Show loader while checking authorization
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="90vh">
                <BkSpinner />
                <Typography variant="h6" mt={2} color="textSecondary" sx={{font: "bold", textAlign: "center", color: "white", marginLeft : "20px" }}>
                Loading.. Please wait..
              </Typography>
              </Box>
            ) : isAuthorized(moduleMapping["Matched Trends"]) ? (
                  <MatchedTrendsPagePlus />
                ) : (
                  <NoAccessPlus moduleName="Matched Trends"/>
                )}
              </RequireAuth>
            }
          />

          <Route path="*" element={<NoMatchPlus />} />
        </Route>
      </Routes>
    </Router>
  );
}
export const ColorModeContext = createContext({ toggleColorMode: () => { } });

const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === "dark"
      ? {
          // Dark mode palette settings
          primary: {
            main: "hsl(130, 44%, 63%)",
          },
          secondary: {
            main: "#007FC4",
          },
          background: {
          },
        }
      : {
          // Light mode palette settings
          primary: {
            main: "#007FC4",
          },
          secondary: {
            main: "#0A948F",
          },
          background: {
          },
        }),
  },
  matchedTrends: {
    background: {
      paper: mode === "dark" ? "#1A1A1A" : "#0000001f",
    },
  },
});


export function App() {
  const [mode, setMode] = useState<"light" | "dark">("dark");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MyRoutes />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}